import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout,
  SEO,
  CJournalJumbotron,
  CBreadCrumb,
  CJournalLead,
  CProfileMedia,
  CJournalText,
  CWideImgMedia,
  CFloatingImg,
  CBtnList,
  LWrap,
  CQuoteBox,
  CSingleImg,
  CChapterHeading,
  CLabelTextList,
  CJournalMedia,
  CMedia,
  CTileImg,
} from "../../../components/_index";
import { text } from "stream/consumers";

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  console.log(data.markdownRemark);
  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: "pickup",
              path: "/pickup/",
            },
          ],
          current: {
            label: frontmatter?.title.includes("-")
              ? frontmatter?.title.split("-")[0]
              : frontmatter?.title,
          },
        }}
      />
      <CJournalJumbotron
        data={{
          label: "ザ ロイヤルパークホテル 東京羽田",
          date: "2023年12月18日",
          title: (
            <>
              どんなことも楽しさに変える。
              <br />
              空港併設ホテルで働くスタッフの、とある4days
            </>
          ),
          text: "ザ ロイヤルパークホテル 東京羽田／黒木 梓マネージャー",
          img: {
            src: "/assets/images/journal/article09/kv.png",
          },
          imgSp: {
            src: "/assets/images/journal/article09/kv__sp.png",
          },
        }}
      />
      <div className="u_pb60">
        <LWrap size="small">
          <CJournalLead
            lead={
              <>
                早朝から飛び立つ人々に、最終便で深夜に到着する人々。さまざまな言語が行き交う羽田空港第3ターミナルの一角にホテルがあることは意外と知られていないのではー。
                <br />
                国籍も目的地も滞在時間も異なる人々が羽を休める空港併設ホテルには、ひとときのくつろぎを提供するため奔走するスタッフの姿がある。そこで今回は、開業時から在籍するザ
                ロイヤルパークホテル
                東京羽田の黒木梓マネージャーの仕事ぶりに密着。空港という“日常のなかの非日常”で奔走するスタッフの想いを、彼女の視線から紐解いていく。
              </>
            }
          />
        </LWrap>
      </div>
      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article09/img_chapter01.png",
            },
            imgSp: {
              src: "/assets/images/journal/article09/img_chapter01__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "01",
            },
            title: (
              <>
                <span>ルーティンの身支度、</span>
                <span>日々変わる雰囲気</span>
              </>
            ),
          }}
        />
        <LWrap>
          <CLabelTextList
            exClass="flex_row"
            data={[
              {
                label: "#Day1",
                text: <>夜間にピークを迎える、東京羽田のチェックイン</>,
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    ここは日本の玄関口、羽田空港第3ターミナル。
                    <br />
                    スーツケースを持った人々で賑わい、日本語や英語のアナウンスが絶えず響きわたるー。そんな旅の高揚感に包まれた3階出発ロビーに直結するザ
                    ロイヤルパークホテル
                    東京羽田で働くのは、2014年の開業時からともに歩んできた黒木マネージャー。
                  </p>
                  <p>
                    「緊急事態が発生すると一瞬ドキッとしますが、上に立つ者として常に冷静でいたいと心がけています。もう、緊張することも慌てることもないですね」。そう語る彼女の口調には、ベテランらしい優しさと頼もしさが滲み出ている。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article09/img_chapter01_01.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  この日は久々の夜勤。慣れた手つきで身支度をしながら「ここで働いていると、いろいろな国の文化を知ることができるんです。海外のお客様から現地のお土産をいただくこともあるんですよ」と、キュッとスカーフを最後に結ぶ。さあ、今夜はどんな出来事が待っているのだろうかー。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article09/img_chapter01_02.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  すでに21時をまわるというのに、フロントは落ち着く気配がない。いつもこんなに賑わっているのかと問いかけると「一般的なホテルと異なり、東京羽田は時間が遅くなればなるほどフロントが混み合うんです。24時の時点で残りのチェックインが20件程度であれば少ない方ですよ」と、黒木マネージャーは英語での対応に加え、英語以外の言語が必要なときにはジェスチャーや漢字での筆談を交えながらのコミュニケーションを楽しんでいた。そんな最中でも、スタッフやお客様にさりげなく目を配ることは忘れてはいない。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article09/img_chapter01_03.png",
              alt: "",
            }}
            caption={<>空港併設という立地から、宿泊客の60％が外国人</>}
          />
          <CJournalText
            data={{
              text: (
                <>
                  細やかな気配りに長けた黒木マネージャーだが、聞けば前職もホテルスタッフだったという。「元々は航空会社で働きたくて……。けれども夢が叶わず、それならば人と関わることが好きだからとホテル業界を目指しました。以前は都心のホテルで働いていたのですが、羽田空港にホテルができるというのをたまたま目にして。ここならば、これまでの経験をいかしつつ空港で働けるチャンスと思い飛び込みました」。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article09/img_chapter01_04.png",
              alt: "",
            }}
            caption={
              <>
                開業時から使い続けている時計。救命措置など有事の際に必要になるとの理由から秒針付きを購入した
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  悪天候の際にはフライト情報はもちろん、欠航による延泊や宿泊が必要なお客様の数を急いで確認しなければならないなど、東京羽田は常に時間との戦い。フライト状況に大きく左右される空港併設ホテルは「考えることが多いので、身体よりも頭が疲れるんですよね」と、優雅な空間とは裏腹にスタッフの頭の中はフル回転。「今日のように大きな出来事のない平穏な一日でも、ホテルの裏側はいつも緊張感に包まれているんです」。
                </>
              ),
            }}
          />

          <CLabelTextList
            exClass="flex_row"
            data={[
              {
                label: "#Day２",
                text: <>日本の玄関口で働く意味を再認識</>,
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  空港併設ホテルの朝は、ひときわ慌ただしい。「出発までフロントで荷物を預かってほしい」というチェックアウトを済ませた人々の声を受け、スーツケースにスノーボードに自転車と、ありとあらゆる荷物をバックヤードに運び込んでいくー。
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article09/img_chapter01_05.png",
                  alt: "",
                },
                caption: <>繁忙期にはロビーまで預かりの荷物があふれることも</>,
              },
              {
                img: {
                  src: "/assets/images/journal/article09/img_chapter01_06.png",
                  alt: "",
                },
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  この日の黒木マネージャーは夜勤スタッフから引き継ぎを受けると、すぐさまチェックアウトの業務へ。「開業前はチェックアウト後に旅立つことを想定して、荷物の預かりは少ないだろうと踏んでいたのですが蓋を開けると……」と話しながらも瞬く間に増えていく預かりの荷物。開業とともに現場に携わってきた彼女だからこそ、ホテル側が思い描いていたカタチとお客様が望むカタチは、ときに異なるものだと知っている。
                </>
              ),
            }}
          />

          <CSingleImg
            img={{
              src: "/assets/images/journal/article09/img_chapter01_07.png",
              alt: "",
            }}
            caption={
              <>
                15平米とコンパクトながら、“中継地”として過ごすには十分すぎる仕様のプレミアム
                コンフォート シングル
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>「ホテルで働くうえで、大切にしていることは？」</p>
                  <p>
                    こんな問いを投げかけてみると「入社3年目のころですが、欧米の方がチェックインに来られたので何気なく英語で対応したところ『なんで英語で話すの？僕は日本に住んでいるのに、あなたは私の目の色や肌の色で判断したでしょう』と返ってきたんです。私は無意識に見た目で人を判断していたんだ、無意識に人を傷つけることもあるんだ……」と。この出来事は、今も常に心に留めているという。
                  </p>
                  <p>
                    「あなたは日本の玄関口で働いているんだからね」そう優しくも厳しい言葉を投げかけてくれたお客様とは一体どうなったのか……。その後を聞いてみると、意外にも頻繁に利用してくれているそう。ときに厳しい指摘や自身を見つめ直すような苦しい出来事もあるというが「それでも仕事は楽しむもの」。だからこそ後輩たちにもマニュアル通りの仕事ではなく、さまざまな選択肢を持つことで広がる仕事の楽しみを知ってほしいと願っている。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article09/img_chapter01_08.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  「本当に困っていたら手助けしますが、手取り足取り教えてしまうと後輩は育たないし、本人も自分がやりたいと思う接客が楽しめなくなると思うんです。のびのびと働いてほしいので、私は見守るだけ。子どもを育てているみたいに」と、後輩から相談を受けたときにも答えは教えないのが黒木流。母親のようなやわらかな笑みがあふれた。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article09/img_chapter01_09.png",
              alt: "",
            }}
            caption={
              <>
                後輩から相談を受けた際は、マニュアル通りに教えるのではなくヒントを与えつつ“見守りたい”という
              </>
            }
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article09/img_chapter02.png",
            },
            imgSp: {
              src: "/assets/images/journal/article09/img_chapter02__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "02",
            },
            title: (
              <>
                <span className="u_pr0_pc">まるで異国。国内唯一の</span>
                <span>トランジットホテル</span>
              </>
            ),
          }}
        />
        <LWrap>
          <CLabelTextList
            exClass="flex_row"
            data={[
              {
                label: "Day3",
                text: <>扉を抜け、旅立ち前のひとときをおもてなし</>,
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  この日の勤務は、出国エリアにある日本唯一のトランジットホテル。そう、東京羽田にはもうひとつの顔がある。
                  <br />
                  通常であればパスポートと保安検査が必要な出国エリア。その中で働くためには一般の搭乗客と同じように検査を受けなければならず、黒木マネージャーのように10年近く勤務するスタッフであっても、セキュリティチェックを受けるときには身が引き締まる。
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article09/img_chapter02_01.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article09/img_chapter02_02.png",
                  alt: "",
                },
              },
            ]}
            caption={
              <>
                DUTY FREEが並ぶ華やかな出国エリアにあるザ ロイヤルパークホテル
                東京羽田 トランジット
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  「1時間単位で使えるリフレッシュルームでは、サッとシャワーを浴びて出ていく方もいるなど、ここのホテルで過ごす時間はギュッと凝縮されています」と、出発ロビー側のホテルよりもさらに時間にシビア。ただ、この数時間の休息があるかないかは、旅において非常に大きい。「なかには高齢のご両親のために、トランジットエリアでの宿泊をプレゼントされた方もいらっしゃいます。自宅から空港までの移動だけでも疲れてしまいますからね」と、せわしない旅立ちの前のゆとりは何ものにも代え難い。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article09/img_chapter02_03.png",
              alt: "",
            }}
            caption={
              <>
                トランジットホテルには17室を完備。和をイメージした絵画が飾られるなど、出発ロビー側の客室と意匠が異なる
              </>
            }
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article09/img_chapter02_04.png",
                  alt: "",
                },
                caption: (
                  <>
                    汗を流したりパッキングを整えたりと、プライベートな時間が得られるリフレッシュルームは24時間利用可能
                  </>
                ),
              },
              {
                img: {
                  src: "/assets/images/journal/article09/img_chapter02_05.png",
                  alt: "",
                },
                caption: (
                  <>
                    リフレッシュルームの滞在でも使えるフリードリンクを完備したラウンジ
                  </>
                ),
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  そんな旅の隙間時間を思い出に変えるのも、トランジットホテルでの仕事のひとつ。「時差ボケで眠れないんだよね……と、深夜にふらっとフロントに訪れたお客様と話をすることもあるんです。家族の話だったり身につけている香水の話だったりと、たわいもないことですが」。
                  <br />
                  濃密ながらも静かな時間が流れるトランジットホテルでの会話は、きっと海外旅行者の癒しになっているのだろう。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article09/img_chapter02_06.png",
              alt: "",
            }}
            caption={<>海外のお客様との世間話は英会話の練習にもなっている</>}
          />

          <CLabelTextList
            exClass="flex_row"
            data={[
              {
                label: "Day4",
                text: <>レイオーバー⁉︎一気に慌ただしくなる現場</>,
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  黒木マネージャーは、この日もトランジットホテル勤務。「室数の少ないトランジットホテルでは、一人での勤務が基本なんです。ここで一人で対応できたら、怖いものはもうないですよ」。そう笑う彼女はチェックインの対応を終えてバックヤードへ。翌日以降の客室手配などの事務作業を、この隙間時間に手際よく行なっていく。
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article09/img_chapter02_07.png",
                  alt: "",
                },
                caption: (
                  <>清掃が完了しているかなど、客室の稼働状況は頻繁に確認</>
                ),
              },
              {
                img: {
                  src: "/assets/images/journal/article09/img_chapter02_08.png",
                  alt: "",
                },
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    そうこうしているうちに、電話のライトが点灯する。すぐさま黒木マネージャーはバックヤードからフロントに向かい、フライトスケジュールに目を向けた。
                    <br />
                    「どうしたんですか？」と聞くと「レイオーバー（何らかのトラブルによる大幅遅延や欠航）ですね」と、少し顔が険しくなる。
                  </p>
                  <p>
                    フライト状況に大きく左右されるトランジットホテルの中でも、特に慌ただしくなるのが天候や機材トラブルによるレイオーバー。航空会社からの案内をもとに一気にお客様が流れ込むほか、パイロットをはじめとした航空会社のスタッフも休息を求めることから、フロントは一気にごった返す。それを、たった一人で対応しなければならない……。ベテランであっても、レイオーバー時の対応が一番大変だという。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article09/img_chapter02_09.png",
              alt: "",
            }}
            caption={
              <>
                長年の経験から、急な電話とフライトスケジュールのみで、空港の状況が読み取れる
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  臨機応変な対応が求められるホテルにおいて、さらに機転を利かせる必要がある空港併設ホテルは、楽しいことよりも大変なことが上回るのではないだろうかー。
                  <br />
                  落ち着きを取り戻したトランジットホテルで、そんな疑問を投げかけてみると、彼女らしいポジティブな答えが返ってきた。
                  <br />
                  「落ち込むことはあるんですが、そういうときに限って不思議とお客様と素敵なコミュニケーションが生まれるんです。感謝の気持ちを受け取ると、もう少し頑張ろうと思えるんですよね」。
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article09/img_chapter03.png",
            },
            imgSp: {
              src: "/assets/images/journal/article09/img_chapter03__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "03",
            },
            title: (
              <>
                <span className="u_pr0_pc">開業からコロナ禍を経て</span>
                <span>感じる、羽田のいま</span>
              </>
            ),
          }}
        />
        <LWrap>
          <CJournalText
            data={{
              text: (
                <>
                  「よくぞここに建ててくれた」
                  <br />
                  「搭乗口に近くて本当にありがたい」
                  <br />
                  これらは開業時によく耳にした言葉だというが、そこから数年してコロナ禍に突入。誰も見たことのない閑散とした空港の景色を経て、今ようやく日常を取り戻しつつある。
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article09/img_chapter03_01.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article09/img_chapter03_02.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article09/img_chapter03_03.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article09/img_chapter03_04.png",
                  alt: "",
                },
              },
            ]}
            caption={
              <>
                滑走路を模した廊下など、館内の至るところに空の旅のモチーフが。散りばめられたアートを探すのも楽しみのひとつ
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  「冬場はウィンタースポーツを目的に日本に訪れる海外の人も多く、手荷物の預かりにスノーボードの板が多くなったことで人の戻りを実感しました」と話せるのは、彼女が開業時からホテルとともに歩んできたからこそ。コロナ禍を経た現在の様子を、お客様の要望や荷物だけで読み取ることができるのだ。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article09/img_chapter03_05.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  「ハイヒールが似合う凛とした女性に昔から憧れがあるんです」
                  <br />
                  そう話す彼女の立ち振る舞いには、凜とした美しさや安心感が滲み出ている。空港で働くという憧れにたどりついて早10年。今ではすっかり後輩からも頼られるポジションであり、東京羽田の行く末を広い視野で見守っている。
                  <br />
                  「空港という便利な立地にホテルがあるので、ぜひ多くの方にご利用いただきたいのですが、世間での認知度はまだまだ。滞在先の最初の選択肢にロイヤルパークの名前が出るようにしていくためにも、まずは日本のお客様が海外で東京羽田のよさを広めてくれたら嬉しいですね」。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article09/img_chapter03_06.png",
              alt: "",
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article09/img_chapter03_07.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    今日の天候はどうだろう。
                    <br />
                    これから何が起こるのだろう。
                  </p>
                  <p>
                    緊張感のある空港という立地だからこそ、業務と並行して確認することは山ほどある。雰囲気も目まぐるしく変わる。けれども「空港×ホテル」の組み合わせは、世界中の人との関わりを心から楽しむ黒木マネージャーの天職に違いない。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article09/img_chapter03_08.png",
              alt: "",
            }}
            caption={
              <>
                第3ターミナル出発ロビーに直結するホテルのゲート。手荷物検査場まで徒歩30秒の距離にある
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    フロントでは各々が自身の知識をもとに、お客様におすすめの観光地を案内している。そんな様子を彼女は今日も穏やかに見守っている。
                    <br />
                    「今のお客様が喜んでくださった対応でも、次の方が喜んでくださるとは限らない。答えがないから日々もがいているんですが、だからこそピタッとお客様の要望にはまったときにホテルスタッフとしてのやりがいを感じます。選択肢をたくさん持つと仕事がもっと楽しめるようになる。これは後輩のスタッフみんなに知ってもらいたいことですね」。
                  </p>
                  <p>
                    さまざまな国の人々が往来し、ときには思いがけない出来事も起こる東京羽田。そこで奔走する黒木マネージャーの4日間から見えてきたものは、どんなときでも楽しむことを忘れないホテルスタッフとしての心意気だった。
                  </p>
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <ul className="c_noteList">
                    <li>本記事・画像は2023年10月時点のものです。</li>
                  </ul>
                </>
              ),
            }}
          />

          <p className="u_fwb u_tac u_mt80">ザ ロイヤルパークホテル 東京羽田</p>
          <CBtnList
            exClass="u_mt20"
            data={[
              {
                label: "ホテルサイト",
                color: "bgBlack",
                link: {
                  href: "https://www.royalparkhotels.co.jp/the/tokyohaneda/",
                  blank: true,
                },
                icon: "blank",
              },
            ]}
          />
        </LWrap>
      </section>

      <div className="l_sect02 u_bgGray">
        <LWrap>
          <CBtnList
            data={[
              {
                label: "ロイヤルパークホテルズJournal",
                link: {
                  href: "/pickup/#journal",
                },
                iconBefore: true,
                icon: "arrowReverse",
              },
            ]}
          />
        </LWrap>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
